import * as types from "./types";

export default {
  [types.MUTATE_SAVING_INTERVIEW]: (state, payload) => {
    state.savingInterview = payload;
  },
  [types.MUTATE_COMMENTS]: (state, payload) => {
    state.comments[payload.interview_session] = payload.data;
  },
  [types.MUTATE_CUSTOMER_INFO]: (state, payload) => {
    state.customerInfo = payload;
  },
  [types.MUTATE_OCCUPATIONS]: (state, payload) => {
    state.occupations = payload;
  },
  [types.MUTATE_GOALS]: (state, payload) => {
    state.goals = payload;
  },
  [types.MUTATE_SELECTED_GOALS]: (state, payload) => {
    state.selectedGoals = payload;
  },
  [types.MUTATE_LIFE_INSURANCE]: (state, payload) => {
    state.lifeInsurance = payload;
  },
  [types.MUTATE_PROFESSIONAL_INSURANCE]: (state, payload) => {
    state.professionalInsurance = payload;
  },
  [types.MUTATE_ACQUISITIONS]: (state, payload) => {
    state.acquisitions = payload;
  },
  [types.MUTATE_PATRIMONY]: (state, payload) => {
    state.patrimony = payload;
  },
  [types.MUTATE_HEALTH_PLAN]: (state, payload) => {
    state.healthPlan = payload;
  },
  [types.MUTATE_RETIREMENT]: (state, payload) => {
    state.retirement = payload;
  },
  [types.MUTATE_INTEREST_IN_LIVING_ABROAD]: (state, payload) => {
    state.interestInLivingAbroad = payload;
  },
  [types.MUTATE_MONTHLY_INVESTMENT]: (state, payload) => {
    state.monthlyInvestment = payload;
  },
  [types.MUTATE_MONTHLY_INVESTMENT_CALCULATION]: (state, payload) => {
    state.monthlyInvestmentCalculation = payload;
  },
  [types.MUTATE_DEBTS]: (state, payload) => {
    state.debts = payload;
  },
  [types.MUTATE_CONTRACT_SERVICE_TYPES]: (state, payload) => {
    state.contractServiceTypes = payload;
  },
  [types.MUTATE_PAYMENT_METHOD_TYPES]: (state, payload) => {
    state.paymentMethodTypes = payload;
  },
  [types.MUTATE_CLOSER_CONSULTANTS]: (state, payload) => {
    state.closerConsultants = payload;
  },
  [types.MUTATE_PROPOSAL]: (state, payload) => {
    state.proposal = payload;
  },
  [types.MUTATE_PROPOSAL_CALCULATION]: (state, payload) => {
    state.proposalCalculation = payload;
  },
  [types.MUTATE_CASH_FLOW_CATEGORIES]: (state, payload) => {
    state.cashFlowCategories = payload;
  },
  [types.MUTATE_CASH_FLOW]: (state, payload) => {
    state.cashFlow = payload;
  },
  [types.MUTATE_CASH_FLOW_CALCULATION]: (state, payload) => {
    state.cashFlowCalculation = payload;
  },
  [types.MUTATE_ADDITIONAL_INFORMATION]: (state, payload) => {
    state.additionalInformation ={
      ...state.additionalInformation, 
      ...payload
    };
  },
  [types.MUTATE_INVESTMENTS]: (state, payload) => {
    state.investments = payload;
  },
  [types.MUTATE_INTERVIEW_FORM]: (state, payload) => {
    state.interviewForm = payload;
  },
  [types.MUTATE_CUSTOMER_CONTRACTS]: (state, payload) => {
    state.customerContracts = payload;
  },
  [types.MUTATE_INTERVIEW_CASH_FLOW_LOADING]: (state, payload) => {
    state.interviewCashFlowLoading = payload;
  },
  [types.MUTATE_INTERVIEW_PROPOSAL_LOADING]: (state, payload) => {
    state.interviewProposalLoading = payload;
  },
  [types.MUTATE_SUBSCRIPTION_PLANS]: (state, payload) => {
    state.subscriptionPlans = payload;
  },
  [types.MUTATE_CUSTOMER_SUBSCRIPTION_PLANS]: (state, payload) => {
    state.customerSubscriptionPlans = payload;
  },

  [types.MUTATE_CUSTOMER_ALTERED_INTERVIEW]: (state) => {
    state.customerAlteredInterview = true;
  },
  [types.MUTATE_SEARCH_INTERVIEW]: (state, payload) => {
    state.searchInterview = payload;
  },
  [types.MUTATE_SET_SEARCH_INTERVIEW]: (state, payload) => {
    state.setCustomerSatisfactionSurvey = payload;
  },
  [types.MUTATE_LOADING_CALCULATION_PER_MONTH]: (state, loading) => {
    state.loadingCalculationPerMonth = loading;
  },
  [types.MUTATE_LOADING_PROPOSAL]: (state, loading) => {
    state.loadingProposal = loading;
  },
  [types.MUTATE_TRANSACTION_PAYMENTS]: (state, payload) => {
    state.transactionPayments = payload;
  },
  [types.MUTATE_PATRIMONY_SUM]: (state, payload) => {
    state.patrimonySum = payload;
  },
  [types.MUTATE_LOADING_PATRIMONY]: (state, payload) => {
    state.loadingPatrimony = payload;
  },
  [types.MUTATE_CURRENT_CURRENCY]: (state, payload) => {
    state.currentCurrency = payload;
  },
};
