<template>
  <form id="futuro-customer-financial" class="my-5" @submit.prevent>
    <div class="card">
      <div class="row align-items-center justify-content-between">
        <div class="card-title col-auto mb-3">
          <img
            src="~@/assets/img/ic-info-money.svg"
            alt="Futuro | Consultoria Financeira"
          />
          <h2>Informações financeiras</h2>
        </div>
        <spinner v-if="loading" class="mr-2" />
      </div>
      <div class="row">
        <div class="mb-3 mt-0 gy-3 col-md-6 col-12">
          <!-- Cartões -->

          <div class="card-title mt-3 mb-3">
            <label class="text-white">Cartões de crédito</label>
          </div>
          <div
            v-for="(card, index) in cards"
            :key="`card-${index}`"
            class="mb-3 mt-0 inner-card"
          >
            <div class="row mb-3 gy-3">
              <div class="col-12">
                <label :for="`cardName-${index}`" class="form-label">
                  Nome
                </label>
                <input
                  :id="`cardName-${index}`"
                  v-model="card.name"
                  type="text"
                  :disabled="isDetail"
                  class="form-control"
                  @blur="preSave()"
                />
              </div>
            </div>
            <div class="row mb-3 gy-3">
              <div class="col-md-6 col-12">
                <label :for="`cardMonthlyFee-${index}`" class="form-label">
                  Anuidade mensal
                </label>
                <input
                  :id="`cardMonthlyFee-${index}`"
                  v-model.lazy="card.monthlyFee"
                  v-money="moneyFormat"
                  type="text"
                  :disabled="isDetail"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].monthlyFee.length,
                  }"
                  @blur="
                    calculateAnnualFee(cards, index);
                    preSave();
                  "
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].monthlyFee.length
                    "
                  >
                    Digite um valor válido
                  </span>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <label :for="`cardAnnualFee-${index}`" class="form-label">
                  Anuidade total
                </label>
                <input
                  :id="`cardMonthlyFee-${index}`"
                  v-model.lazy="card.annualFee"
                  v-money="moneyFormat"
                  :disabled="isDetail"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].annualFee.length,
                  }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].annualFee.length
                    "
                  >
                    Digite um valor válido
                  </span>
                </div>
              </div>
            </div>
            <div class="row mb-3 gy-3">
              <div class="col-md-6 col-12">
                <label :for="`cardLimit-${index}`" class="form-label">
                  Limite
                </label>
                <input
                  :id="`cardLimit-${index}`"
                  v-model.lazy="card.limit"
                  v-money="moneyFormat"
                  :disabled="isDetail"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].limit.length,
                  }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].limit.length
                    "
                  >
                    Digite um valor válido
                  </span>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <label :for="`cardAverageExpense-${index}`" class="form-label">
                  Gasto médio
                </label>
                <input
                  :id="`cardAverageExpense-${index}`"
                  v-model.lazy="card.averageExpense"
                  v-money="moneyFormat"
                  :disabled="isDetail"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].averageExpense
                        .length,
                  }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].averageExpense
                        .length
                    "
                  >
                    Digite um valor válido
                  </span>
                </div>
              </div>
            </div>
            <div class="row mb-3 gy-3">
              <div class="col-md-6 col-12">
                <label :for="`cardPoints-${index}`" class="form-label">
                  Pontos
                </label>
                <input
                  :id="`cardPoints-${index}`"
                  v-model="card.points"
                  type="text"
                  :disabled="isDetail"
                  class="form-control"
                  @blur="preSave()"
                />
              </div>
              <div class="col-md-6 col-12">
                <label :for="`cardCashback-${index}`" class="form-label">
                  Cashback
                </label>
                <input
                  :id="`cardCashback-${index}`"
                  v-model.lazy="card.cashback"
                  v-money="percentageFormat"
                  :disabled="isDetail"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].cashback.length,
                  }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.cards.$dirty &&
                      v$.cards.$each.$response.$errors[index].cashback.length
                    "
                  >
                    Digite um valor válido
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3 justify-content-end">
              <div class="col-auto">
                <template v-if="!isDetail && cards.length > 1">
                  <button
                    class="btn btn-red"
                    @click.prevent="remove(cards, index)"
                  >
                    Excluir cartão
                  </button>
                </template>
              </div>
            </div>
          </div>
          <template v-if="!isDetail">
            <button
              class="btn btn-orange col-auto mx-1"
              @click.prevent="addCard"
            >
              Add cartão de crédito
            </button>
          </template>
        </div>

        <!-- Contas corrente -->

        <div class="mb-3 mt-0 gy-3 col-md-6 col-12">
          <div class="card-title mt-3 mb-3">
            <label class="text-white">Contas-correntes</label>
          </div>
          <div
            v-for="(bankAccount, index) in bankAccounts"
            :key="`bankAccount-${index}`"
            class="inner-card mb-3"
          >
            <div class="row mb-3 gy-3">
              <div class="col-md-4 col-12">
                <label :for="`bankAccountName-${index}`" class="form-label">
                  Nome
                </label>
                <input
                  :id="`bankAccountName-${index}`"
                  v-model="bankAccount.name"
                  type="text"
                  :disabled="isDetail"
                  class="form-control"
                  @blur="preSave()"
                />
              </div>
              <div class="col-md-4 col-12">
                <label
                  :for="`bankAccountMonthlyFee-${index}`"
                  class="form-label"
                >
                  Tarifa mensal
                </label>
                <input
                  :id="`bankAccountMonthlyFee-${index}`"
                  v-model.lazy="bankAccount.monthlyFee"
                  v-money="moneyFormat"
                  type="text"
                  class="form-control"
                  :disabled="isDetail"
                  :class="{
                    'is-invalid':
                      v$.bankAccounts.$dirty &&
                      v$.bankAccounts.$each.$response.$errors[index].monthlyFee
                        .length,
                  }"
                  @blur="
                    calculateAnnualFee(bankAccounts, index);
                    preSave();
                  "
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.bankAccounts.$dirty &&
                      v$.bankAccounts.$each.$response.$errors[index].monthlyFee
                        .length
                    "
                  >
                    Digite um valor válido
                  </span>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <label
                  :for="`bankAccountAnnualFee-${index}`"
                  class="form-label"
                >
                  Tarifa total
                </label>
                <input
                  :id="`bankAccountMonthlyFee-${index}`"
                  v-model.lazy="bankAccount.annualFee"
                  v-money="moneyFormat"
                  :disabled="isDetail"
                  type="text"
                  class="form-control"
                  :class="{
                    'is-invalid':
                      v$.bankAccounts.$dirty &&
                      v$.bankAccounts.$each.$response.$errors[index].annualFee
                        .length,
                  }"
                  @blur="preSave()"
                />
                <div class="invalid-feedback">
                  <span
                    v-if="
                      v$.bankAccounts.$dirty &&
                      v$.bankAccounts.$each.$response.$errors[index].annualFee
                        .length
                    "
                  >
                    Digite um valor válido
                  </span>
                </div>
              </div>
            </div>
            <div class="row mt-3 justify-content-end">
              <div class="col-auto">
                <template v-if="!isDetail && bankAccounts.length > 1">
                  <button
                    class="btn btn-red"
                    @click.prevent="remove(bankAccounts, index)"
                  >
                    Excluir conta corrente
                  </button>
                </template>
              </div>
            </div>
          </div>
          <template v-if="!isDetail">
            <button
              class="btn btn-orange col-auto mx-1"
              @click.prevent="addBankAccount"
            >
              Add conta-corrente
            </button>
          </template>
        </div>
      </div>

      <div class="row mt-3 mb-3 px-3">
        <div class="col-12 totalizer debts-value-container">
          <span>Custos bancários:</span>
          <span>{{ formatCurrency(costsTotal) }}</span>
        </div>
      </div>

      <comments-section
        v-if="interviewSection && token"
        :interview-section="interviewSection"
        :token="token"
      />
    </div>
  </form>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { helpers, minLength } from "@vuelidate/validators";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { VMoney } from "v-money";
import {
  getPercentageFormat,
  getFloatFormat,
} from "../../../helpers/formatting";
import {
  floatToMoney,
  moneyToFloat,
  floatToPercentage,
} from "../../../helpers/converters";
import { getFinancialAdditionalInformation } from "../../../helpers/interview_payloads";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import Spinner from "../../shared/components/Spinner";
import CommentsSection from "./CommentsSection";

export default {
  components: {
    Spinner,
    CommentsSection,
  },
  directives: {
    money: VMoney,
  },
  props: {
    interviewSection: {
      type: String,
      default: "",
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    return { v$: useVuelidate({ $registerAs: props.interviewSection }) };
  },
  data() {
    return {
      cards: [
        {
          name: "",
          monthlyFee: "",
          annualFee: "",
          limit: "",
          averageExpense: "",
          points: "",
          cashback: "",
        },
      ],
      bankAccounts: [
        {
          name: "",
          monthlyFee: "",
          annualFee: "",
        },
      ],
      token: "",
      loading: false,
      tempPayload: "",
    };
  },
  validations() {
    return {
      cards: {
        $each: helpers.forEach({
          name: {},
          monthlyFee: {
            minLength: minLength(7),
          },
          annualFee: {
            minLength: minLength(7),
          },
          limit: {
            minLength: minLength(7),
          },
          averageExpense: {
            minLength: minLength(7),
          },
          cashback: {
            minLength: minLength(7),
          },
        }),
      },
      bankAccounts: {
        $each: helpers.forEach({
          name: {},
          monthlyFee: {
            minLength: minLength(7),
          },
          annualFee: {
            minLength: minLength(7),
          },
        }),
      },
    };
  },
  computed: {
    ...mapGetters({
      formatCurrency: types.FORMAT_CURRENCY,
      formatedCurrency: types.FORMATED_CURRENCY,
      additionalInformation: types.ADDITIONAL_INFORMATION,
    }),
    moneyFormat: function () {
      return this.formatedCurrency
    },
    percentageFormat: function () {
      return getPercentageFormat();
    },
    floatFormat: function () {
      return getFloatFormat();
    },
    costsTotal: function () {
      return _.sumBy([...this.cards, ...this.bankAccounts], (item) => {
        return moneyToFloat(item.annualFee);
      });
    },
  },
  async mounted() {
    this.token = this.$route.params.customerId;
    await this.fetchAll();
  },
  methods: {
    ...mapActions({
      getAdditionalInformation: types.GET_ADDITIONAL_INFORMATION,
      autoSaveAdditionalInformation: types.AUTO_SAVE_ADDITIONAL_INFORMATION,
    }),
    ...mapMutations({
      mutateAdditionalInformation: types.MUTATE_ADDITIONAL_INFORMATION,
    }),
    async fetchAdditionalInformation() {
      this.loading = true;
      try {
        const response = await this.getAdditionalInformation(
          this.$route.params.customerId
        );
        const { cards, bank_accounts } = response.data;

        if (cards.length > 0) {
          this.cards = cards.map((card) => {
            const {
              name,
              monthly_fee,
              annual_fee,
              limit,
              average_expense,
              points,
              cashback,
            } = card;
            return {
              name,
              monthlyFee: floatToMoney(monthly_fee),
              annualFee: floatToMoney(annual_fee),
              limit: floatToMoney(limit),
              averageExpense: floatToMoney(average_expense),
              points: points,
              cashback: floatToPercentage(cashback),
            };
          });
        }

        if (bank_accounts.length > 0) {
          this.bankAccounts = bank_accounts.map((bank_account) => {
            const { name, monthly_fee, annual_fee } = bank_account;
            return {
              name,
              monthlyFee: floatToMoney(monthly_fee),
              annualFee: floatToMoney(annual_fee),
            };
          });
        }

        const payload = getFinancialAdditionalInformation(this);
        this.mutateAdditionalInformation(payload);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível obter as informações adicionais do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchAll() {
      this.loading = true;
      try {
        await Promise.all([this.fetchAdditionalInformation()]);
      } catch (error) {
        this.$vfm.show("message", {
          type: "error",
          messages: [
            "Não foi possível carregar as informações financeiras do cliente.",
            "Entre em contato com o time de TI antes de prosseguir com a operação.",
          ],
        });
      } finally {
        this.loading = false;
      }
    },
    addCard() {
      this.cards.push({
        name: "",
        monthlyFee: "",
        annualFee: "",
        limit: "",
        averageExpense: "",
        points: "",
        cashback: "",
      });
    },
    addBankAccount() {
      this.bankAccounts.push({
        name: "",
        monthlyFee: "",
        annualFee: "",
      });
    },
    remove(array, i) {
      array.splice(i, 1);
      this.preSave();
    },
    preSave() {
      const payload =  {...this.additionalInformation, ...getFinancialAdditionalInformation(this)};
      const currentPayload = JSON.stringify(payload);
      if (this.tempPayload !== currentPayload) {
        this.tempPayload = currentPayload;
        this.mutateAdditionalInformation(payload);
        this.autoSave(payload);
      }
    },
    autoSave(payload) {
      this.loading = true;
      this.autoSaveAdditionalInformation(payload)
        .catch((error) => {
          this.$vfm.show("message", {
            type: "error",
            messages: [
              "Não foi possível salvar automaticamente as informações financeiras do cliente.",
              "Entre em contato com o time de TI antes de prosseguir com a operação.",
            ],
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    calculateAnnualFee(array, index) {
      const item = array[index];
      item.annualFee = floatToMoney(moneyToFloat(item.monthlyFee) * 12);
    },
  },
};
</script>

<style lang="scss" scoped>
.totalizer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 20px;
  &.debts-value-container {
    background-image: var(--red-gradient);
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}

.card-title {
  img {
    height: 30px !important;
  }
}
</style>
